import React, { useState, useEffect } from "react";
import { func, number } from "prop-types";
import { isMobile } from "react-device-detect";
import { navigate } from "gatsby";
import axios from "axios";
import { Form } from "antd";
import Heading from "../../components-v2/Base/HeadingBuilder";
import { stepTitles, subTitle, title } from "../../data/become-a-partner";
import CtaSecondary from "../../components-v2/Base/CtaSecondary";
import CtaPrimary from "../../components-v2/Base/CtaPrimary";
import Step1 from "./step-1";
import Step2 from "./step-2";
import Step3 from "./step-3";
import { emailValidationRegExp, phoneValidationRegExp } from "../../constants";
import rasi from "../../images/random/partners/rasi.png";
import penn from "../../images/random/partners/penn.png";
import ohio from "../../images/random/partners/ohio.png";
import ny from "../../images/random/partners/new-york.png";
import digitalPour from "../../images/random/partners/digital-pour.png";
import shogo from "../../images/random/partners/shogo.png";
import { encode } from "../../components/Forms/EnterpriseForm";
import bg2 from "../../images/svg/double-circle-top-right-big.svg";
import bg from "../../images/svg/vector-14.svg";
import { BECOME_A_PARTNER, submitForm } from "../../utils/firebase/forms";

const netlifyFormName = "Become a partner";

const stepToTitleMap = {
  1: stepTitles.step1,
  2: stepTitles.step2,
  3: stepTitles.step3,
};

const BecomeAPartnerForm = ({ step, setStep }) => {
  const [partnerPocName, setPartnerPocName] = useState("");
  const [partnerPocEmail, setPartnerPocEmail] = useState("");
  const [partnerPocPhone, setPartnerPocPhone] = useState("");
  const [industry, setIndustry] = useState("");

  const [compLocation, setCompLocation] = useState("");
  const [compEmployeesNumber, setCompEmployeesNumber] = useState("");
  const [compClientsNumber, setCompClientsNumber] = useState("");
  const [compAreasOfOperation, setCompAreasOfOperation] = useState("");
  const [compTimeInBusiness, setCompTimeInBusiness] = useState("");
  const [compIdealCustomerProfile, setCompIdealCustomerProfile] = useState("");

  const [leadOrigin, setLeadOrigin] = useState("");
  const [mutualCustomers, setMutualCustomers] = useState("");
  const [mutualCustomersDetails, setMutualCustomersDetails] = useState("");
  const [otherPosCompanies, setOtherPosCompanies] = useState("");
  const [otherPosDetails, setOtherPosDetails] = useState("");
  const [carryIntegrationLift, setCarryIntegrationLift] = useState("");
  const [integrationLiftDetails, setIntegrationLiftDetails] = useState("");
  const [integrationTiming, setIntegrationTiming] = useState("");

  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const [step1Errors, setStep1Errors] = useState({
    name: true,
    email: true,
    phone: true,
    industry: true,
  });

  const [step2Errors, setStep2Errors] = useState({
    location: true,
    employeesNumber: true,
    clientsNumber: true,
    areasOfOperation: true,
    timeInBusiness: true,
    idealCustomerProfile: true,
  });

  const [step3Errors, setStep3Errors] = useState({
    leadOrigin: true,
    mutualCustomers: true,
    mutualCustomersDetails: true,
    otherPosCompanies: true,
    otherPosDetails: false,
    carryIntegrationLift: true,
    integrationLiftDetails: true,
    integrationTiming: true,
  });

  const onSubmit = async () => {
    setLoading(true);

    const dataToSend = {
      partnerPocName,
      partnerPocEmail,
      partnerPocPhone,
      industry,
      compLocation,
      compEmployeesNumber,
      compClientsNumber,
      compAreasOfOperation,
      compTimeInBusiness,
      compIdealCustomerProfile,
      leadOrigin,
      mutualCustomers,
      mutualCustomersDetails,
      otherPosCompanies,
      otherPosDetails,
      carryIntegrationLift,
      integrationLiftDetails,
      integrationTiming,
    };
    const result = await submitForm(dataToSend, BECOME_A_PARTNER);

    if (result) {
      setLoading(false);
      navigate("/partners/become-a-partner/thank-you");
    } else {
      setLoading(false);
    }

    // await fetch(`/`, {
    //   method: `POST`,
    //   body: encode({
    //     "form-name": netlifyFormName,
    //     partnerPocName,
    //     partnerPocEmail,
    //     partnerPocPhone,
    //     industry,
    //     compLocation,
    //     compEmployeesNumber,
    //     compClientsNumber,
    //     compAreasOfOperation,
    //     compTimeInBusiness,
    //     compIdealCustomerProfile,
    //     leadOrigin,
    //     mutualCustomers,
    //     mutualCustomersDetails,
    //     otherPosCompanies,
    //     otherPosDetails,
    //     carryIntegrationLift,
    //     integrationLiftDetails,
    //     integrationTiming,
    //   }),
    // })
    //   .then(
    //     () => {
    //       setLoading(false);
    //       navigate("/partners/become-a-partner/thank-you");
    //     },
    //     (err) => {
    //       setLoading(false);
    //     }
    //   )
    //   .catch((err) => {
    //     setLoading(false);
    //   });
  };

  useEffect(() => {
    if (isMobile) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [step]);

  useEffect(() => {
    if (step === 1) {
      const hasErrors = Object.values(step1Errors).includes(true);
      if (!hasErrors) {
        setStep(2);
        setShowErrors(false);
      }
    }

    if (step === 2) {
      const hasErrors = Object.values(step2Errors).includes(true);
      if (!hasErrors) {
        setStep(3);
        setShowErrors(false);
      }
    }

    if (step === 3) {
      const hasErrors = Object.values(step3Errors).includes(true);
      if (!hasErrors) {
        setShowErrors(false);
        if (!loading) {
          onSubmit();
        }
      }
    }
  }, [step1Errors, step2Errors, step3Errors]);

  const checkStep1Errors = async () => {
    const response = await axios.get("/api/handler", {
      params: { email: partnerPocEmail.trim().toLowerCase() },
    });

    const emailStatus = response?.data?.result?.response?.result;
    const emailInvalid =
      emailStatus !== "valid" &&
      emailStatus !== "catchall" &&
      emailStatus !== "unknown";

    // if (emailStatus !== "valid" && emailStatus !== "catchall") {
    //   setStep1Errors({ ...step1Errors, email: true });
    //   setLoading(false);
    //   return;
    // }

    setStep1Errors({
      name: !partnerPocName.trim().length > 0,
      email: emailInvalid,
      phone: !phoneValidationRegExp.test(partnerPocPhone),
      industry: !industry.trim().length > 0,
    });
  };

  const checkStep2Errors = () => {
    setStep2Errors({
      location: !compLocation.trim().length > 0,
      employeesNumber: !compEmployeesNumber.trim().length > 0,
      clientsNumber: !compClientsNumber.trim().length > 0,
      areasOfOperation: !compAreasOfOperation.trim().length > 0,
      timeInBusiness: !compTimeInBusiness.trim().length > 0,
      idealCustomerProfile: !compIdealCustomerProfile.trim().length > 0,
    });
  };

  const checkStep3Errors = () => {
    setStep3Errors({
      leadOrigin: !leadOrigin.trim().length > 0,
      mutualCustomers: !mutualCustomers.trim().length > 0,
      mutualCustomersDetails: !mutualCustomersDetails.trim().length > 0,
      otherPosCompanies: !otherPosCompanies.trim().length > 0,
      // otherPosDetails: !otherPosDetails.trim().length > 0,
      carryIntegrationLift: !carryIntegrationLift.trim().length > 0,
      integrationLiftDetails: !integrationLiftDetails.trim().length > 0,
      integrationTiming: !integrationTiming.trim().length > 0,
    });
  };

  const onNextClick = async () => {
    if (step === 1) {
      await checkStep1Errors();
      setShowErrors(true);
    }
    if (step === 2) {
      checkStep2Errors();
      setShowErrors(true);
    }
    if (step === 3) {
      checkStep3Errors();
      setShowErrors(true);
    }
  };

  const onBackClick = () => setStep((st) => st - 1);

  const stepBaseStyles =
    "text-[18px] w-[32px] h-[32px] rounded-full flex items-end justify-center mx-3 border border-solid";
  const stepCompleteStyles = "text-white bg-primary border-primary";
  const stepIncompleteStyles = "text-black-400 border-black-400";

  const step1Props = {
    partnerPocName,
    setPartnerPocName,
    partnerPocEmail,
    setPartnerPocEmail,
    partnerPocPhone,
    setPartnerPocPhone,
    industry,
    setIndustry,
    showErrors,
    errors: step1Errors,
  };

  const step2Props = {
    compLocation,
    setCompLocation,
    compEmployeesNumber,
    setCompEmployeesNumber,
    compClientsNumber,
    setCompClientsNumber,
    compAreasOfOperation,
    setCompAreasOfOperation,
    compTimeInBusiness,
    setCompTimeInBusiness,
    compIdealCustomerProfile,
    setCompIdealCustomerProfile,
    showErrors,
    errors: step2Errors,
  };

  const step3Props = {
    leadOrigin,
    setLeadOrigin,
    mutualCustomers,
    setMutualCustomers,
    mutualCustomersDetails,
    setMutualCustomersDetails,
    otherPosCompanies,
    setOtherPosCompanies,
    otherPosDetails,
    setOtherPosDetails,
    carryIntegrationLift,
    setCarryIntegrationLift,
    integrationLiftDetails,
    setIntegrationLiftDetails,
    integrationTiming,
    setIntegrationTiming,
    showErrors,
    errors: step3Errors,
  };

  const stepsMap = {
    1: <Step1 {...step1Props} />,
    2: <Step2 {...step2Props} />,
    3: <Step3 {...step3Props} />,
  };

  return (
    <>
      {/* Netlify form */}
      <form name={netlifyFormName} data-netlify="true" hidden>
        <input type="text" name="partnerPocName" />
        <input type="text" name="partnerPocEmail" />
        <input type="text" name="partnerPocPhone" />
        <input type="text" name="industry" />
        <input type="text" name="compLocation" />
        <input type="text" name="compEmployeesNumber" />
        <input type="text" name="compClientsNumber" />
        <input type="text" name="compAreasOfOperation" />
        <input type="text" name="compTimeInBusiness" />
        <input type="text" name="compIdealCustomerProfile" />
        <input type="text" name="leadOrigin" />
        <input type="text" name="mutualCustomers" />
        <input type="text" name="mutualCustomersDetails" />
        <input type="text" name="otherPosCompanies" />
        <input type="text" name="otherPosDetails" />
        <input type="text" name="carryIntegrationLift" />
        <input type="text" name="integrationLiftDetails" />
        <input type="text" name="integrationTiming" />
      </form>

      <section
        className={`bg-primary-30 w-full mx-auto pt-40
      pb-64 md:pb-48 lg:pb-48 2xl:pb-80 relative
      `}
      >
        <img src={bg} alt="background" className="absolute top-0 right-0 " />
        <img
          src={bg2}
          alt="background"
          className="opacity-0 md:opacity-100 absolute bottom-0 left-0 "
        />

        <div className="max-w-1440 w-full mx-auto px-4 lg:px-10">
          <Heading
            level={1}
            injectionType={isMobile ? 2 : 1}
            withBlueSymbol
            className="text-center relative"
          >
            {title}
          </Heading>
          <p className="relative mx-auto text-center text-black-300 text-p max-w-[630px]">
            {subTitle}
          </p>

          <div className="flex flex-row justify-center my-8 md:my-10">
            <div className={`${stepBaseStyles} ${stepCompleteStyles}`}>1</div>

            <span
              className="mb-[16px] w-[70px] md:w-[100px]"
              style={{ borderBottom: "1px dashed #8A95A6" }}
            />

            <div
              className={`${stepBaseStyles} ${
                step > 1 ? stepCompleteStyles : stepIncompleteStyles
              }`}
            >
              2
            </div>

            <span
              className="mb-[16px] w-[70px] md:w-[100px]"
              style={{ borderBottom: "1px dashed #8A95A6" }}
            />

            <div
              className={`${stepBaseStyles} ${
                step === 3 ? stepCompleteStyles : stepIncompleteStyles
              }`}
            >
              3
            </div>
          </div>
        </div>
      </section>

      <section className="relative max-w-1440 w-full mb-40">
        <div
          className={`
            bg-white rounded-lg
            -mt-64 md:-mt-48 lg:-mt-48 2xl:-mt-72
            mx-4 lg:mx-40 2xl:mx-64
            px-6 lg:px-10 2xl:px-24
            py-6 lg:py-10
          `}
          style={{ boxShadow: "0px 32px 60px rgba(0, 0, 0, 0.07)" }}
        >
          <Heading
            level={3}
            injectionType={3}
            className="text-black text-center"
            withBlueSymbol
          >
            {stepToTitleMap[step]}
          </Heading>

          {stepsMap[step]}

          <div className="flex flex-col-reverse md:flex-row items-center gap-x-8 justify-center mt-4 md:mt-10">
            {step > 1 && (
              <CtaSecondary
                className="w-full md:w-auto justify-center my-4 md:my-auto"
                title="Go back"
                reverseIcon
                large
                onClick={onBackClick}
              />
            )}
            {step <= 3 && (
              <CtaPrimary
                className="w-full md:w-auto justify-center my-4 md:my-auto"
                title={step < 3 ? "Next step" : "Submit"}
                onClick={onNextClick}
                disabled={loading}
              />
            )}
          </div>
        </div>
      </section>

      {/* <section */}
      {/*  className={` */}
      {/*  grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 */}
      {/*  content-center */}
      {/*  gap-x-7 gap-y-20 */}
      {/*  mt-20 mb-40 mx-auto */}
      {/*  max-w-[1088px] w-full */}
      {/*  px-4 */}
      {/* `} */}
      {/* > */}
      {/*  <div className="flex flex-col justify-between items-center"> */}
      {/*    <div className="w-[96px] h-[96px] flex items-center justify-center"> */}
      {/*      <img src={penn} alt="reviews" className="w-full h-auto" /> */}
      {/*    </div> */}
      {/*    <div className="text-14 font-bold text-black mt-4 text-center max-w-[164px] h-[50px]"> */}
      {/*      Pennsylvania Restaurant Association */}
      {/*    </div> */}
      {/*  </div> */}

      {/*  <div className="flex flex-col justify-between items-center"> */}
      {/*    <div className="w-[96px] h-[96px] flex items-center justify-center"> */}
      {/*      <img src={ohio} alt="reviews" className="w-full h-auto" /> */}
      {/*    </div> */}
      {/*    <div className="text-14 font-bold text-black mt-4 text-center max-w-[164px] h-[50px]"> */}
      {/*      Ohio Restaurant Association */}
      {/*    </div> */}
      {/*  </div> */}

      {/*  <div className="flex flex-col justify-between items-center"> */}
      {/*    <div className="w-[96px] h-[96px] flex items-center justify-center"> */}
      {/*      <img src={digitalPour} alt="reviews" className="w-full h-auto" /> */}
      {/*    </div> */}
      {/*    <div className="text-14 font-bold text-black mt-4 text-center max-w-[164px] h-[50px]"> */}
      {/*      Digital Pour */}
      {/*    </div> */}
      {/*  </div> */}

      {/*  <div className="flex flex-col justify-between items-center"> */}
      {/*    <div className="w-[96px] h-[96px] flex items-center justify-center"> */}
      {/*      <img src={ny} alt="reviews" className="w-full h-auto" /> */}
      {/*    </div> */}
      {/*    <div className="text-14 font-bold text-black mt-4 text-center max-w-[164px] h-[50px]"> */}
      {/*      New York Restaurant Association */}
      {/*    </div> */}
      {/*  </div> */}

      {/*  <div className="flex flex-col justify-between items-center"> */}
      {/*    <div className="w-[96px] h-[96px] flex items-center justify-center"> */}
      {/*      <img src={rasi} alt="reviews" className="w-full h-auto" /> */}
      {/*    </div> */}
      {/*    <div className="text-14 font-bold text-black mt-4 text-center max-w-[164px] h-[50px]"> */}
      {/*      RASI */}
      {/*    </div> */}
      {/*  </div> */}

      {/*  <div className="flex flex-col justify-between items-center"> */}
      {/*    <div className="w-[96px] h-[96px] flex items-center justify-center"> */}
      {/*      <img src={shogo} alt="reviews" className="w-full h-auto" /> */}
      {/*    </div> */}
      {/*    <div className="text-14 font-bold text-black mt-4 text-center max-w-[164px] h-[50px]"> */}
      {/*      Shogo */}
      {/*    </div> */}
      {/*  </div> */}
      {/* </section> */}
    </>
  );
};

BecomeAPartnerForm.propTypes = {
  step: number.isRequired,
  setStep: func.isRequired,
};

export default BecomeAPartnerForm;

export const labelStyles = "text-black-100 mb-1 font-[15px]";
export const stepStyles = `
      relative grid grid-cols-1 md:grid-cols-2
      gap-x-8
      gap-y-2
    `;
export const errorStyles = "text-danger text-[12px] mt-1";
export const radioErrorStyles = "text-danger text-[12px] -mt-1";
