import React from "react";
import { string, func, any, bool } from "prop-types";
import { Form, Input, Select } from "antd";
import { labelStyles, stepStyles, errorStyles } from "./form";

const { Option } = Select;

const industries = [
  "Sports & Entertainment",
  "Hospitality & Restaurants",
  "Retail",
  "Other",
];

const Step1 = ({
  partnerPocName,
  setPartnerPocName,
  partnerPocEmail,
  setPartnerPocEmail,
  partnerPocPhone,
  setPartnerPocPhone,
  industry,
  setIndustry,
  showErrors,
  errors,
}) => {
  return (
    <div className={stepStyles}>
      <div>
        <div className={labelStyles}>Partner POC name</div>
        <Input
          value={partnerPocName}
          onChange={({ target: { value } }) => setPartnerPocName(value)}
          placeholder="E.g. John"
          size="large"
          style={{
            borderColor: showErrors && errors.name ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.name ? 100 : 0
          }`}
        >
          Please enter a name
        </div>
      </div>

      <div>
        <div className={labelStyles}>Partner POC email</div>
        <Input
          value={partnerPocEmail}
          onChange={({ target: { value } }) => setPartnerPocEmail(value)}
          placeholder="E.g. johndoe@mail.com"
          size="large"
          style={{
            borderColor: showErrors && errors.email ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.email ? 100 : 0
          }`}
        >
          Please enter a valid email
        </div>
      </div>

      <div>
        <div className={`${labelStyles}`}>Partner POC phone number</div>
        <Input
          value={partnerPocPhone}
          onChange={({ target: { value } }) => setPartnerPocPhone(value)}
          placeholder="E.g. 111 222 3333"
          size="large"
          style={{
            borderColor: showErrors && errors.phone ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.phone ? 100 : 0
          }`}
        >
          Please enter a valid phone
        </div>
      </div>

      <div>
        <div className={`${labelStyles}`}>Industry</div>

        <Form.Item
          rules={[
            {
              required: true,
              message: "Please select a state",
            },
          ]}
          validateStatus={showErrors && errors.industry ? "error" : ""}
        >
          <Select
            className="w-full"
            defaultValue={industry}
            onChange={(value) => setIndustry(value)}
            style={{
              borderColor: showErrors && errors.industry ? "#F73E3C" : "",
            }}
          >
            <Option value="" />
            {industries.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
          <div
            className={`${errorStyles} opacity-${
              showErrors && errors.industry ? 100 : 0
            }`}
          >
            Please select an industry
          </div>
        </Form.Item>
      </div>
    </div>
  );
};

Step1.propTypes = {
  partnerPocName: string.isRequired,
  setPartnerPocName: func.isRequired,
  partnerPocPhone: string.isRequired,
  setPartnerPocPhone: func.isRequired,
  partnerPocEmail: string.isRequired,
  setPartnerPocEmail: func.isRequired,
  industry: string.isRequired,
  setIndustry: func.isRequired,
  showErrors: bool.isRequired,
  errors: any.isRequired,
};

export default Step1;
