import React from "react";
import { string, func, bool, any } from "prop-types";
import { Input } from "antd";
import { labelStyles, stepStyles, errorStyles } from "./form";

const Step2 = ({
  compLocation,
  setCompLocation,
  compEmployeesNumber,
  setCompEmployeesNumber,
  compClientsNumber,
  setCompClientsNumber,
  compAreasOfOperation,
  setCompAreasOfOperation,
  compTimeInBusiness,
  setCompTimeInBusiness,
  compIdealCustomerProfile,
  setCompIdealCustomerProfile,
  errors,
  showErrors,
}) => {
  return (
    <div className={stepStyles}>
      <div>
        <div className={labelStyles}>Company location</div>
        <Input
          value={compLocation}
          onChange={({ target: { value } }) => setCompLocation(value)}
          placeholder="E.g. Chicago"
          size="large"
          style={{
            borderColor: showErrors && errors.location ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.location ? 100 : 0
          }`}
        >
          Please enter location
        </div>
      </div>

      <div>
        <div className={labelStyles}>Company number of full time employees</div>
        <Input
          value={compEmployeesNumber}
          onChange={({ target: { value } }) => setCompEmployeesNumber(value)}
          placeholder="E.g. 75"
          size="large"
          style={{
            borderColor: showErrors && errors.employeesNumber ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.employeesNumber ? 100 : 0
          }`}
        >
          Please enter a number
        </div>
      </div>

      <div>
        <div className={labelStyles}>Company number of current clients</div>
        <Input
          value={compClientsNumber}
          onChange={({ target: { value } }) => setCompClientsNumber(value)}
          placeholder="E.g. 48"
          size="large"
          style={{
            borderColor: showErrors && errors.clientsNumber ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.clientsNumber ? 100 : 0
          }`}
        >
          Please enter a number
        </div>
      </div>

      <div>
        <div className={labelStyles}>Company areas of operation</div>
        <Input
          value={compAreasOfOperation}
          onChange={({ target: { value } }) => setCompAreasOfOperation(value)}
          placeholder="E.g. 22"
          size="large"
          style={{
            borderColor: showErrors && errors.areasOfOperation ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.areasOfOperation ? 100 : 0
          }`}
        >
          Please enter a number
        </div>
      </div>

      <div>
        <div className={labelStyles}>
          Company funding history/time in business
        </div>
        <Input
          value={compTimeInBusiness}
          onChange={({ target: { value } }) => setCompTimeInBusiness(value)}
          placeholder="E.g. 7 years"
          size="large"
          style={{
            borderColor: showErrors && errors.timeInBusiness ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.timeInBusiness ? 100 : 0
          }`}
        >
          Please enter the time in business
        </div>
      </div>

      <div>
        <div className={labelStyles}>
          Company's ideal cust. profile / # of locations
        </div>
        <Input
          value={compIdealCustomerProfile}
          onChange={({ target: { value } }) =>
            setCompIdealCustomerProfile(value)
          }
          placeholder="E.g. 15"
          size="large"
          style={{
            borderColor:
              showErrors && errors.idealCustomerProfile ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.idealCustomerProfile ? 100 : 0
          }`}
        >
          Please enter number of locations
        </div>
      </div>
    </div>
  );
};

Step2.propTypes = {
  compLocation: string.isRequired,
  setCompLocation: func.isRequired,
  compEmployeesNumber: string.isRequired,
  setCompEmployeesNumber: func.isRequired,
  compClientsNumber: string.isRequired,
  setCompClientsNumber: func.isRequired,
  compAreasOfOperation: string.isRequired,
  setCompAreasOfOperation: func.isRequired,
  compTimeInBusiness: string.isRequired,
  setCompTimeInBusiness: func.isRequired,
  compIdealCustomerProfile: string.isRequired,
  setCompIdealCustomerProfile: func.isRequired,
  showErrors: bool.isRequired,
  errors: any.isRequired,
};

export default Step2;
