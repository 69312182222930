import React, { useState } from "react";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BecomeAPartnerForm from "../../../page-components/become-a-partner/form";

const AffiliatePage = () => {
  const [step, setStep] = useState(1);

  return (
    <Layout
      transparentHeader
      isLanding
      useNewLPHeader
      useDarkLogo
      className="non-fixed-width"
    >
      <SEO title="SpotOn Become A Partner" />

      <BecomeAPartnerForm step={step} setStep={setStep} />
    </Layout>
  );
};

export default AffiliatePage;
