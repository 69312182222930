import React from "react";
import { string, func, bool, any } from "prop-types";
import { Input, Radio, Space } from "antd";
import { labelStyles, errorStyles, radioErrorStyles } from "./form";

const { TextArea } = Input;

const Step3 = ({
  leadOrigin,
  setLeadOrigin,
  mutualCustomers,
  setMutualCustomers,
  mutualCustomersDetails,
  setMutualCustomersDetails,
  otherPosCompanies,
  setOtherPosCompanies,
  otherPosDetails,
  setOtherPosDetails,
  carryIntegrationLift,
  setCarryIntegrationLift,
  integrationLiftDetails,
  setIntegrationLiftDetails,
  integrationTiming,
  setIntegrationTiming,
  showErrors,
  errors,
}) => {
  return (
    <div className="relative w-full">
      <div className="md:col-span-2">
        <div className={labelStyles}>Where did the lead come from?</div>
        <Input
          value={leadOrigin}
          onChange={({ target: { value } }) => setLeadOrigin(value)}
          placeholder="E.g. John Doe"
          size="large"
          style={{
            borderColor: showErrors && errors.leadOrigin ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.leadOrigin ? 100 : 0
          }`}
        >
          Please enter lead origin
        </div>
      </div>

      <div className="md:col-span-2">
        <div className={labelStyles}>
          Are there mutual customer(s) that are driving the need for the
          integration?
        </div>
        <Radio.Group
          onChange={({ target: { value } }) => setMutualCustomers(value)}
          value={mutualCustomers}
        >
          <Space direction="vertical">
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </Space>
        </Radio.Group>
        <div
          className={`${radioErrorStyles} opacity-${
            showErrors && errors.mutualCustomers ? 100 : 0
          }`}
        >
          Please select an option
        </div>

        <TextArea
          className="mt-4"
          rows={3}
          value={mutualCustomersDetails}
          onChange={({ target: { value } }) => setMutualCustomersDetails(value)}
          style={{
            borderColor:
              showErrors && errors.mutualCustomersDetails ? "#F73E3C" : "",
          }}
          placeholder={
            // eslint-disable-next-line no-nested-ternary
            !mutualCustomers.length
              ? ""
              : mutualCustomers === "yes"
              ? "Please specify"
              : "Justify the need for SpotOn to engage in an integration"
          }
        />

        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.mutualCustomersDetails ? 100 : 0
          }`}
        >
          Please provide some info
        </div>
      </div>

      <div className="md:col-span-2">
        <div className={labelStyles}>
          Do you currently integrate with other POS companies?
        </div>
        <Radio.Group
          onChange={({ target: { value } }) => setOtherPosCompanies(value)}
          value={otherPosCompanies}
        >
          <Space direction="vertical">
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </Space>
        </Radio.Group>
        <div
          className={`${radioErrorStyles} opacity-${
            showErrors && errors.otherPosCompanies ? 100 : 0
          }`}
        >
          Please select an option
        </div>

        {otherPosCompanies === "yes" && (
          <>
            <TextArea
              className="mt-4"
              rows={3}
              value={otherPosDetails}
              onChange={({ target: { value } }) => setOtherPosDetails(value)}
              style={{
                borderColor:
                  showErrors && errors?.otherPosDetails ? "#F73E3C" : "",
              }}
              placeholder="Please specify"
            />

            <div
              className={`${errorStyles} opacity-${
                showErrors && errors?.otherPosDetails ? 100 : 0
              }`}
            >
              Please provide some info
            </div>
          </>
        )}
      </div>

      <div className="md:col-span-2">
        <div className={labelStyles}>Would you carry the integration lift?</div>
        <Radio.Group
          onChange={({ target: { value } }) => setCarryIntegrationLift(value)}
          value={carryIntegrationLift}
        >
          <Space direction="vertical">
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </Space>
        </Radio.Group>
        <div
          className={`${radioErrorStyles} opacity-${
            showErrors && errors.carryIntegrationLift ? 100 : 0
          }`}
        >
          Please select an option
        </div>

        <TextArea
          className="mt-4"
          rows={3}
          value={integrationLiftDetails}
          onChange={({ target: { value } }) => setIntegrationLiftDetails(value)}
          style={{
            borderColor:
              showErrors && errors.integrationLiftDetails ? "#F73E3C" : "",
          }}
          placeholder={
            // eslint-disable-next-line no-nested-ternary
            !carryIntegrationLift.length
              ? ""
              : carryIntegrationLift === "yes"
              ? "Please share details on how the interface would look like and what you would need from SpotOn to make this happen"
              : "Justify the need for SpotOn to assign resources to integration."
          }
        />

        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.integrationLiftDetails ? 100 : 0
          }`}
        >
          Please provide some info
        </div>
      </div>

      <div className="md:col-span-2">
        <div className={labelStyles}>
          What kind of timing are you looking at for this integration?{" "}
        </div>
        <Input
          value={integrationTiming}
          onChange={({ target: { value } }) => setIntegrationTiming(value)}
          placeholder="E.g. 2 months"
          size="large"
          style={{
            borderColor:
              showErrors && errors.integrationTiming ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.integrationTiming ? 100 : 0
          }`}
        >
          Please enter expected integration time
        </div>
      </div>

      {/* <div> */}
      {/*  <div className={labelStyles}>Tell us about your deal</div> */}
      {/*  <TextArea */}
      {/*    rows={6} */}
      {/*    value={additionalInfo} */}
      {/*    onChange={({ target: { value } }) => setAdditionalInfo(value)} */}
      {/*    style={{ */}
      {/*      borderColor: showErrors && errors.additionalInfo ? "#F73E3C" : "", */}
      {/*    }} */}
      {/*  /> */}
      {/*  <div */}
      {/*    className={`${errorStyles} opacity-${ */}
      {/*      showErrors && errors.additionalInfo ? 100 : 0 */}
      {/*    }`} */}
      {/*  > */}
      {/*    Please write a bit more about the potential deal */}
      {/*  </div> */}
      {/* </div> */}
    </div>
  );
};

Step3.propTypes = {
  leadOrigin: string.isRequired,
  setLeadOrigin: func.isRequired,
  mutualCustomers: string.isRequired,
  setMutualCustomers: func.isRequired,
  mutualCustomersDetails: string.isRequired,
  setMutualCustomersDetails: func.isRequired,
  otherPosCompanies: string.isRequired,
  setOtherPosCompanies: func.isRequired,
  otherPosDetails: string.isRequired,
  setOtherPosDetails: func.isRequired,
  carryIntegrationLift: string.isRequired,
  setCarryIntegrationLift: func.isRequired,
  integrationLiftDetails: string.isRequired,
  setIntegrationLiftDetails: func.isRequired,
  integrationTiming: string.isRequired,
  setIntegrationTiming: func.isRequired,
  showErrors: bool.isRequired,
  errors: any.isRequired,
};

export default Step3;
